import logo from './logo.svg';
import './App.css';

import tree from "../src/assets/dpohon2.png"
import tree2 from "../src/assets/dpohon.png"
import grass from "../src/assets/grass.png"
import skyline from "../src/assets/skylineztrez.png"

import {RemoveScrollBar} from "react-remove-scroll-bar";

import layers1Sky from "../src/assets/layers/skyonly.png"
import layers2Building from "../src/assets/layers/buildingonly.png"
import layers3GrassOnly from "../src/assets/layers/grassonly.png"
import layers4Tree from "../src/assets/layers/tree4.png"
import layers5Tree from "../src/assets/layers/tree3.png"
import layers6Tree from "../src/assets/layers/tree2.png"
import layers7Tree from "../src/assets/layers/tree1.png"

import cloud from "../src/assets/cloudz.png"
import {useState} from "react";

function App() {

    const [count,setCount] = useState(0);

    const renderClouds = () =>{
        return <>
            <img
                style={{
                    position: "absolute",
                    height: "12vw",
                    width: "7.5vw",
                    objectFit: "contain",
                    animation: "movingRight 30s linear 0s infinite, floating1 3s linear 0s infinite",
                }}
                src={cloud}/>

            <img
                style={{
                    position: "absolute",
                    height: "12vw",
                    width: "7.5vw",
                    left : "-8vw",
                    objectFit: "contain",
                    animation: "movingRight 30s linear 3s infinite, floating2 2s linear 0s infinite",
                }}
                src={cloud}/>

            <img
                style={{
                    position: "absolute",
                    height: "12vw",
                    width: "7.5vw",
                    left : "-8vw",
                    objectFit: "contain",
                    animation: "movingRight 30s linear 7s infinite, floating1 3s linear 0s infinite",
                }}
                src={cloud}/>

            <img
                style={{
                    position: "absolute",
                    height: "12vw",
                    width: "7.5vw",
                    left : "-8vw",
                    objectFit: "contain",
                    animation: "movingRight 30s linear 12s infinite, floating2 2s linear 0s infinite",
                }}
                src={cloud}/>


            <img
                style={{
                    position: "absolute",
                    height: "12vw",
                    width: "7.5vw",
                    left : "-8vw",
                    objectFit: "contain",
                    animation: "movingRight 30s linear 12s infinite, floating2 2s linear 0s infinite",
                }}
                src={cloud}/>

            <img
                style={{
                    position: "absolute",
                    height: "12vw",
                    width: "7.5vw",
                    left : "-8vw",
                    objectFit: "contain",
                    animation: "movingLeft 30s linear 4s infinite, floating2 2s linear 0s infinite",
                }}
                src={cloud}/>

            <img
                style={{
                    position: "absolute",
                    height: "12vw",
                    width: "7.5vw",
                    left : "-8vw",
                    objectFit: "contain",
                    animation: "movingLeft 30s linear 0s infinite, floating3 3s linear 0s infinite",
                }}
                src={cloud}/>

            <img
                style={{
                    position: "absolute",
                    height: "12vw",
                    width: "7.5vw",
                    left : "-8vw",
                    objectFit: "contain",
                    animation: "movingLeft 30s linear 18s infinite, floating1 2s linear 0s infinite",
                }}
                src={cloud}/>

            <img
                style={{
                    position: "absolute",
                    height: "12vw",
                    width: "7.5vw",
                    left : "-8vw",
                    objectFit: "contain",
                    animation: "movingRight 30s linear 14s infinite, floating3 3s linear 0s infinite",
                }}
                src={cloud}/>
        </>
    }

    const renderDrawings = () =>{
        return <>

            {
                count > 0 &&
                <img
                    style={{
                        position: "absolute", bottom: 0,
                        height: 421, width: 322, objectFit: "contain",
                        animation: "moveIn 0.3s, bounce 0.3s ease-out 0.3s 2",
                        // animationDuration : "1s",
                    }}
                    src={tree}/>
            }

            {
                count > 1 &&
                <img
                    style={{
                        position: "absolute", bottom: 0, left : "23vw",
                        height: 421, width: 322, objectFit: "contain",
                        animation: "moveIn 0.3s, bounce 0.3s ease-out 0.3s 2",
                        // animationDuration : "1s",
                    }}
                    src={tree2}/>
            }

            {
                count > 2 &&
                <img
                    style={{
                        position: "absolute", bottom: 0, left : "48vw",
                        height: 421, width: 322, objectFit: "contain",
                        animation: "moveIn 0.3s, bounce 0.3s ease-out 0.3s 2",
                        // animationDuration : "1s",
                    }}
                    src={tree}/>
            }

            {
                count > 3 &&
                <img
                    style={{
                        position: "absolute", bottom: 0, left : "76vw",
                        height: 421, width: 322, objectFit: "contain",
                        animation: "moveIn 0.3s, bounce 0.3s ease-out 0.3s 2",
                        // animationDuration : "1s",
                    }}
                    src={tree2}/>
            }
            {/*<img*/}
            {/*    style={{*/}
            {/*        position: "absolute", bottom: 0,*/}
            {/*        height: 421, width: 322, objectFit: "contain",*/}
            {/*        animation: "moveIn 0.3s, bounce 0.3s ease-out 0.3s 2",*/}
            {/*        // animationDuration : "1s",*/}
            {/*    }}*/}
            {/*    src={tree}/>*/}

        </>
    }

    return (
        <div
            onClick={()=>{
                setCount(count+1)
            }}
            style={{
                background : `#b7dbf3`,
                // backgroundSize : "cover"
            }}
        >
            <RemoveScrollBar/>
            <div style={{
                height: "100vh",
                width: "100vw"
            }}>

                {/*<img*/}
                {/*    style={{*/}
                {/*        position: "absolute",*/}
                {/*        height: "100vh",*/}
                {/*        width: "100vw",*/}
                {/*        objectFit: "cover",*/}
                {/*        animation: "slowlyMovingRightIllusion 30s steps(360) 0s infinite",*/}
                {/*    }}*/}
                {/*    src={layers1Sky}/>*/}

                <img
                    style={{
                        position: "absolute",
                        height: "100vh",
                        width: "100vw",
                        objectFit: "cover",
                        animation: "slowlyMovingRightIllusion 60s steps(720) 0s infinite",
                        // animation: "slowlyMovingRightIllusion 10s steps(120) linear 0s infinite",
                    }}
                    src={layers2Building}/>

                {/*<div style={{*/}
                {/*    width: "100vw",*/}
                {/*    height: "100vh",*/}
                {/*    position: "absolute",*/}
                {/*    top: "0vh",*/}
                {/*    background: `url(${layers2Building})`,*/}
                {/*    backgroundRepeat: "repeat-x",*/}
                {/*    backgroundSize: "cover",*/}
                {/*    animation: "slowlyMovingRightIllusion 5s linear 0.3s infinite",*/}
                {/*}}/>*/}

                <div style={{
                    width: "100vw",
                    height: "100vh",
                    position: "absolute",
                    top: "0vh",
                    background: `url(${layers3GrassOnly})`,
                    backgroundRepeat: "repeat-x",
                    backgroundSize: "cover"
                }}/>

                <div style={{
                    width: "100vw",
                    height: "100vh",
                    position: "absolute",
                    top: "-2vh",
                    background: `url(${layers4Tree})`,
                    backgroundRepeat: "repeat-x",
                    backgroundSize: "cover"
                }}/>

                <div style={{
                    width: "100vw",
                    height: "100vh",
                    position: "absolute",
                    top: "-2vh",
                    background: `url(${layers5Tree})`,
                    backgroundRepeat: "repeat-x",
                    backgroundSize: "cover"
                }}/>

                <div style={{
                    width: "100vw",
                    height: "100vh",
                    position: "absolute",
                    top: "-2vh",
                    background: `url(${layers6Tree})`,
                    backgroundRepeat: "repeat-x",
                    backgroundSize: "cover"
                }}/>

                <div style={{
                    width: "100vw",
                    height: "100vh",
                    position: "absolute",
                    top: "-2vh",
                    background: `url(${layers7Tree})`,
                    backgroundRepeat: "repeat-x",
                    backgroundSize: "cover"
                }}/>

                {renderDrawings()}

                <div style={{
                    objectFit: "contain",
                    width: "100vw",
                    height: "15vh",
                    position: "absolute",
                    bottom: "-6vh",
                    background: `url(${grass})`,
                    backgroundRepeat: "repeat-x",
                    backgroundSize: "contain"
                }}>

                </div>

            </div>

            {renderClouds()}
            <div style={{
                fontWeight : 900,
                position : "absolute",
                width : "100%",
                bottom : "15vh",
                textAlign : "center",
                fontSize : "3em",
                color : "#00000030"
            }}>
                CLICK TO PLANT A TREE
            </div>

        </div>
    );
}

export default App;
